import { nanoid } from 'nanoid';

import { useConstant } from './useConstant';

interface Options {
  prefix?: string;
  suffix?: string;
}
export const useId = (options?: Options) => {
  return useConstant(() => {
    const parts: string[] = [];
    if (options?.prefix) parts.push(options.prefix);
    parts.push(nanoid());
    if (options?.suffix) parts.push(options.suffix);
    return parts.join('-');
  });
};
