import {
  CSSProperties,
  forwardRef,
  memo,
  MutableRefObject,
  useEffect,
  useRef
} from 'react';
import isEqual from 'react-fast-compare';

import { useEffectSkipInitial } from '@/common/hooks/useEffectSkipInitial';
import { usePrevious } from '@/common/hooks/usePrevious';

import { QRCodeStyling, QrStylingOptions } from './QrCodeStyling';

interface Props {
  defaults?: Partial<QrStylingOptions>;
  value?: Partial<QrStylingOptions>;
  containerStyle?: CSSProperties;
  onInit?: (qrCode: QRCodeStyling, container: HTMLDivElement) => void;
  dataAttributes?: Record<string, string>;
  onClick?: (e: React.MouseEvent) => void;
}
export const QrCodeGenerator = memo(
  forwardRef<QRCodeStyling, Props>(
    (
      { defaults, value, onInit, containerStyle, dataAttributes = {}, onClick },
      propRef: MutableRefObject<QRCodeStyling>
    ) => {
      const fallbackRef = useRef<QRCodeStyling>();
      const ref = propRef || fallbackRef;

      const elementRef = useRef<HTMLDivElement>();
      const previousValue = usePrevious(value);
      useEffectSkipInitial(() => {
        if (ref.current && !isEqual(value, previousValue)) {
          ref.current.update(value);
        }
      }, [value, previousValue]);

      useEffect(() => {
        if (!ref.current) {
          ref.current = new QRCodeStyling(value || defaults);
          onInit?.(ref.current, elementRef.current);
        }
        ref.current.append(elementRef.current);
      }, []);

      return (
        <div
          {...dataAttributes}
          onClick={onClick}
          style={{
            height: value?.height || defaults?.height,
            width: value?.width || defaults?.width,
            ...(containerStyle || {})
          }}
          ref={elementRef}
        />
      );
    }
  ),
  (previous, next) =>
    isEqual(
      {
        value: previous.value,
        containerStyle: previous.containerStyle
      },
      {
        value: next.value,
        containerStyle: next.containerStyle
      }
    )
);
